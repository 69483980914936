import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../images/logo_black.png";

import Footer from "./components/Footer";
import Loading from "./components/Loading";

import "../css/style.css";

const Step30_Workshops = () => {
    const [user, setUser] = useState({
        salutation: "",
        firstname: "",
        lastname: "",
        company: "",
        function: "",
        email: "",
        vrMandate: ""
    });

    const theEvent = JSON.parse(sessionStorage.getItem("FUWEvent"));
    const remarks = JSON.parse(theEvent.remarks);
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [order, setOrder] = useState({});
    const [bookedTickets, setBookedTickets] = useState([]);

    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState({});
    const [token] = useState(sessionStorage.getItem("token"));

    const [selectedWorkShops, setSelectedWorkShops] = useState([]);
    const [selectedPanelDiscussions, setSelectedPanelDiscussions] = useState([]);

    // fetching resources
    const { language } = useParams();

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        requestResources();

    }, [language]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestOrderInformation();

    }, []);

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestOrderInformation = () => {
        setLoading(true);

        axios
            .get(`${baseUrl}/ShopBasket/Order/${orderId}`)
            .then((res) => {
                setBookedTickets(res.data.tickets);
                setOrder(res.data);

                setTimeout(() => setLoading(false), 500);
            })
            .catch((error) => {
                console.error(error.response.data);
                navigate(`/${language}/sessiontimeout`);
            });
    };

    const mapWorkshops = () => {
        let workshops = remarks["Workshops"];

        return (
            workshops &&
            workshops.length > 0 &&
            workshops.map(
                (ws, index) => (
                    <div key={ws.Id} className="mb-4">
                        <div className="row">
                            <div className="col-md-1 mt-4">
                                <b className="textBlue">{ws.Id}</b>
                            </div>
                            <div className="col-md-11 mt-4 ps-md-0">
                                <h5><b>{ws.Title}</b></h5>
                            </div>
                        </div>
                        {
                            ws.Speakers &&
                            ws.Speakers.length > 0 &&
                            ws.Speakers.map(
                                (spk, index2) => (
                                    <div key={index2 + "_speaker"} className="row mt-1">
                                        <div className="offset-md-1 col-md-11 ps-md-0">
                                            <small><b>{spk.Name}</b> {spk.Company}</small>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                )
            )
        );
    };

    const mapPanelDiscussions = () => {
        let panelDiscussions = remarks["PanelDiscussions"];

        return (
            panelDiscussions &&
            panelDiscussions.length > 0 &&
            panelDiscussions.map(
                (pd, index) => (
                    <div key={pd.Id} className="mb-4">
                        <div className="row">
                            <div className="col-md-1 mt-4">
                                <b className="textBlue">{pd.Id}</b>
                            </div>
                            <div className="col-md-11 mt-4 ps-md-0">
                                <h5><b>{pd.Title}</b></h5>
                            </div>
                        </div>
                        {
                            pd.Speakers &&
                            pd.Speakers.length > 0 &&
                            pd.Speakers.map(
                                (spk, index2) => (
                                    <div key={index2 + "_speaker"} className="row mt-1">
                                        <div className="offset-md-1 col-md-11 ps-md-0">
                                            <small><b>{spk.Name}</b> {spk.Company}</small>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                )
            )
        );
    }

    const AddWorkshopOptions = (workShopNumber, ticketOption, ticketId) => {

        let array = selectedWorkShops;

        const exisingArrays = array.filter((array) => array.ticketId === ticketId);

        let workShopArray = [];

        if (exisingArrays.length === 1) {
            if (selectedWorkShops.find((opt) => opt.ticketId == ticketId)[ticketOption]) {
                workShopArray = selectedWorkShops.find((opt) => opt.ticketId == ticketId)[ticketOption]
            }
        }

        if (workShopArray.find(wsa => wsa == workShopNumber)) {
            /* if workshop already exist in array remove it */
            const index = workShopArray.findIndex(wsa => wsa == workShopNumber);
            workShopArray.splice(index, 1);
        } else {
            /* add workshop to array */
            if (workShopArray.length < remarks["MaxSelectedWorkshops"] || ticketOption != "option5") {
                workShopArray.push(workShopNumber);
            }
        }

        let object = {
            ticketId: ticketId,
            [ticketOption]: workShopArray
        }

        if (array.length == 0) {
            array.push(object);
        } else {
            if (exisingArrays.length === 1) {
                array.find((array) => array.ticketId === ticketId)[ticketOption] = workShopArray;
            } else {
                array[array.length] = object;
            }
        }

        setSelectedWorkShops(array);

        onChangeWorkshop(ticketOption, ticketId);
    }


    const AddPanelDiscussionOptions = (workShopNumber, ticketOption, ticketId) => {

        let array = selectedPanelDiscussions;

        const exisingArrays = array.filter((array) => array.ticketId === ticketId);

        let panelDiscussionArray = [];

        if (exisingArrays.length === 1) {
            if (selectedPanelDiscussions.find((opt) => opt.ticketId == ticketId)[ticketOption]) {
                panelDiscussionArray = selectedPanelDiscussions.find((opt) => opt.ticketId == ticketId)[ticketOption]
            }
        }

        if (panelDiscussionArray.find(wsa => wsa == workShopNumber)) {
            /* if panelDiscussion already exist in array remove it */
            const index = panelDiscussionArray.findIndex(wsa => wsa == workShopNumber);
            panelDiscussionArray.splice(index, 1);
        } else {
            /* add panelDiscussion to array */
            if (panelDiscussionArray.length < remarks["MaxSelectedPanelDiscussions"] || ticketOption != "option6") {
                panelDiscussionArray.push(workShopNumber);
            }
        }

        let object = {
            ticketId: ticketId,
            [ticketOption]: panelDiscussionArray
        }

        if (array.length == 0) {
            array.push(object);
        } else {
            if (exisingArrays.length === 1) {
                array.find((array) => array.ticketId === ticketId)[ticketOption] = panelDiscussionArray;
            } else {
                array[array.length] = object;
            }
        }

        setSelectedPanelDiscussions(array);

        onChangePanelDiscussion(ticketOption, ticketId);
    }

    const onChangeWorkshop = (ticketOption, ticketId) => {

        bookedTickets.forEach(t => {
            if (t.id === ticketId) {
                t[ticketOption] = selectedWorkShops.find((ticketOption) => ticketOption.ticketId == ticketId)[ticketOption].join();
            }
        });

        setBookedTickets([...bookedTickets]);
    }

    const onChangePanelDiscussion = (ticketOption, ticketId) => {

        bookedTickets.forEach(t => {
            if (t.id === ticketId) {
                t[ticketOption] = selectedPanelDiscussions.find((ticketOption) => ticketOption.ticketId == ticketId)[ticketOption].join();
            }
        });

        setBookedTickets([...bookedTickets]);
    }

    // validate if min amount of workshops where selected
    let isPageValid = false;

    if (selectedWorkShops.length < bookedTickets.length) {
        isPageValid = false;

    } else {
        let validator = 0;

        if (remarks["Workshops"]?.length > 0) {
            if (selectedWorkShops.length === 0) validator += 1;
            selectedWorkShops.forEach(sws => {
                if (sws["option5"].length < remarks["MinSelectedWorkshops"]) validator += 1;
            });
        }
        if (remarks["PanelDiscussions"]?.length > 0) {
            if (selectedPanelDiscussions.length === 0) validator += 1;
            selectedPanelDiscussions.forEach(spd => {
                if (spd["option6"].length < remarks["MinSelectedPanelDiscussions"]) validator += 1;
            });
        }

        if (validator == 0) isPageValid = true;
    }

    const mapTicketOptions = () => {
        let workshops = remarks["Workshops"];
        let panelDiscussions = remarks["PanelDiscussions"];

        return (
            workshops &&
            workshops.length > 0 &&
            bookedTickets &&
            bookedTickets.length > 0 &&
            bookedTickets.map((bt, index) => (
                <div key={bt.id}>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h5 className="text-uppercase">{bt.firstname} {bt.lastname}</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">{resources.translation.WorkshopsIChoose}</div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            {workshops.map(
                                (ws) => (
                                    <button key={ws.Id + "_1"}
                                        className={"btn btn-light ps-4 pe-4 me-2 workshopButton " +
                                            (selectedWorkShops.length > 0 &&
                                                selectedWorkShops
                                                    .find((opt) => opt.ticketId == bt.id)?.option5?.includes(ws.Id)
                                                ? "workshopButtonActive"
                                                : "")}
                                        onClick={() => AddWorkshopOptions(ws.Id, "option5", bt.id)}>{ws.Id}</button>
                                ))}
                        </div>
                    </div>
                    {panelDiscussions?.length > 0 && <div className="row mt-3">
                        <div className="row">
                            <div className="col-md-12">{resources.translation.WorkshopsPodiumIChoose}</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                {panelDiscussions?.map(
                                    (ws) => (
                                        <button key={ws.Id + "_1"}
                                            className={"btn btn-light ps-4 pe-4 me-2 workshopButton " +
                                                (selectedPanelDiscussions.length > 0 &&
                                                    selectedPanelDiscussions
                                                        .find((opt) => opt.ticketId == bt.id)?.option6?.includes(ws.Id)
                                                    ? "workshopButtonActive"
                                                    : "")}
                                            onClick={() => AddPanelDiscussionOptions(ws.Id, "option6", bt.id)}>{ws.Id}</button>
                                    ))}
                            </div>
                        </div>
                    </div>}

                    {remarks["NeedsContactQuestion"] &&
                        <div>
                            <div className="row">
                                <div className="col-md-12 mt-4">{resources.translation.WorkshopsNeedsContactQuestion
                                }</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    {workshops.map(
                                        (ws) => (
                                            <button key={ws.Id + "_2"}
                                                className={"btn btn-light ps-4 pe-4 me-2 workshopButton " +
                                                    (selectedWorkShops.length > 0 &&
                                                        selectedWorkShops
                                                            .find((opt) => opt.ticketId == bt.id)?.option7?.includes(ws.Id)
                                                        ? "workshopButtonActive"
                                                        : "")}
                                                onClick={() => AddWorkshopOptions(ws.Id, "option7", bt.id)}>{ws.Id}</button>
                                        ))}
                                </div>
                            </div>
                            {!remarks["HideContactQuestionPanelDiscussion"] && panelDiscussions?.length > 0 && <div className="row mt-3">
                                <div className="col-md-12">
                                    {panelDiscussions?.map(
                                        (ws) => (
                                            <button key={ws.Id + "_2"}
                                                className={"btn btn-light ps-4 pe-4 me-2 workshopButton " +
                                                    (selectedPanelDiscussions.length > 0 &&
                                                        selectedPanelDiscussions
                                                            .find((opt) => opt.ticketId == bt.id)?.option8?.includes(ws.Id)
                                                        ? "workshopButtonActive"
                                                        : "")}
                                                onClick={() => AddPanelDiscussionOptions(ws.Id, "option8", bt.id)}>{ws.Id}</button>
                                        ))}
                                </div>
                            </div>}
                        </div>
                    }
                </div>
            ))
        );
    };

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const addWorkshopsToTickets = () => {

        //update ticket address
        bookedTickets.forEach((t, key, bookedTickets) => {

            let ticketBody = {
                salutation: t.salutation,
                firstname: t.firstname,
                name: t.lastname,
                function: t.function,
                company: t.company,
                email: t.email,
                option4: t.option4,
                option5: t.option5,
                option7: t.option7
            };

            if (remarks["PanelDiscussions"]?.length > 0) {
                ticketBody.option6 = t.option6;
                ticketBody.option8 = t.option8;
            }

            axios
                .put(`${baseUrl}/Ticket/${t.id}/Address`, ticketBody)
                .then(() => {
                    if (Object.is(bookedTickets.length - 1, key)) {
                        /* redirect to checkout page if its the last interation */
                        if (order.totalPrice === 0) {
                            changePaymentTypeToFree();
                        } else {
                            navigate(`/${language}/checkout`);
                        }
                    }
                })
                .catch((error) => console.log(error.response.data));
        });
    }

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(() => {
                navigate(`/${language}/confirmation`);
            });
    };

    return (
        <div>
            {loading ? (
                <>
                    <div className="d-none d-lg-block">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="50%"
                            left="50%"
                        />
                    </div>
                    <div className="d-lg-none">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="45%"
                            left="45%"
                        />
                    </div>
                </>
            ) : (
                <>
                    {resources.translation && (
                        <div className="container pt-0 wrapper">
                            <div className="row mt-2">
                                <div className="col-md-4 text-center">
                                    <img src={logo} className="img-fluid" alt="FUW Logo"></img>
                                </div>
                            </div>
                            <div className="row mt-5 text-center">
                                <div className={"col-md-4"}>
                                    <p className="navFUW">{resources.translation._Buyer}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className="navFUW">{resources.translation._Tickets}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className="navFUWActive">{remarks["PageTitle"]}</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12 text-center">
                                    <h2 className="text-uppercase underline50">{remarks["PageTitle"]}</h2>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 mb-4 text-center">
                                    <span className="description">
                                        {remarks["LeadText"]}
                                    </span>
                                </div>
                            </div>

                            {/* workshops */}

                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <h5 className="text-uppercase">{remarks["WorkshopsTitle"]}</h5>
                                </div>
                            </div>

                            {mapWorkshops()}

                            {/* workshops */}

                            {remarks["PanelDiscussions"]?.length > 0 && <div className="row mt-5">
                                <div className="col-md-12">
                                        <h5 className="text-uppercase">{remarks["PanelDiscussionsTitle"]}</h5>
                                </div>
                            </div>}

                            {remarks["PanelDiscussions"]?.length > 0 && mapPanelDiscussions()}


                            {/* ticket options */}

                            {mapTicketOptions()}

                            {/* button next */}

                            <div className={"row mt-5"}>
                                <div className="offset-md-10 col-md-2 text-end">
                                    <button className="btn form-control btnDarkblue" disabled={!isPageValid} onClick={addWorkshopsToTickets}>
                                        {resources.translation._Next}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Footer language={language} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Step30_Workshops;